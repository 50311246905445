/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChoiceDTO } from './choiceDTO';


/**
 * Represents a choice set
 */
export interface ChoiceSetDTO { 
    /**
     * Identifier of ChoiceSet
     */
    choiceSetId?: string;
    /**
     * The name of this choice set
     */
    name: string;
    /**
     * The list of choices in this choice set
     */
    choices?: Array<ChoiceDTO>;
}
