/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StageOfLifeEnum } from './stageOfLifeEnum';
import { UserDTO } from './userDTO';


/**
 * Represents a user profile
 */
export interface ProfileDTO { 
    /**
     * UserIdentityId of the user
     */
    userIdentityId?: string;
    /**
     * User name of the user
     */
    username?: string;
    /**
     * Email of the user
     */
    email?: string;
    /**
     * If true the user can have one or more coached users
     */
    isCoach?: boolean;
    /**
     * Coaches of the user
     */
    coaches?: Array<UserDTO>;
    /**
     * Stages of life of the user
     */
    stages?: Array<StageOfLifeEnum>;
}
