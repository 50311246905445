/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * DTO when inserting or updating answers
 */
export interface UpsertAnswerDTO { 
    /**
     * The id of the question
     */
    questionId?: string;
    /**
     * The id of the choice
     */
    choiceId?: string;
    /**
     * The text of the answer
     */
    answerText?: string;
}
