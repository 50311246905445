/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a comment about a user made by a coach
 */
export interface UserCommentDTO { 
    /**
     * Primary key
     */
    id?: string;
    /**
     * Date/time the comment was made
     */
    createdAt?: Date;
    /**
     * User that made the comment
     */
    createdBy?: string;
    /**
     * Date/time the comment was last updated
     */
    updatedAt?: Date;
    /**
     * Text of the comment
     */
    comment?: string;
}
