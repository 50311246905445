import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalComponent } from '../views/shared/modal/modal.component';

/**
 * Voor het tonen/closen van de verschillende modals
 */
@Injectable()
export class ModalService {
  private modals: Array<ModalComponent> = [];

  constructor() { }

  register(newModal: ModalComponent) {
    const oldModal = this.findModal(newModal.id);
    if (oldModal) {
      this.modals.splice(this.modals.indexOf(oldModal));
    }
    this.modals.push(newModal);
  }

  open(id: string): Observable<void> | false {
    const modal = this.findModal(id);
    if (modal) {
      return modal.open();
    }
    return false;
  }

  close(id: string): boolean {
    const modal = this.findModal(id);
    if (modal) {
      modal.close();
      return true;
    }
    return false;
  }

  isOpen(id: string): boolean {
    const modal = this.findModal(id);
    if (modal) {
      return modal.isOpen();
    }
    return false;
  }

  private findModal(id: string) {
    for (const modal of this.modals) {
      if (modal.id === id) {
        return modal;
      }
    }
    return null;
  }
}
