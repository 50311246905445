/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export type LogEventLevel = 'Verbose' | 'Debug' | 'Information' | 'Warning' | 'Error' | 'Fatal';

export const LogEventLevel = {
    Verbose: 'Verbose' as LogEventLevel,
    Debug: 'Debug' as LogEventLevel,
    Information: 'Information' as LogEventLevel,
    Warning: 'Warning' as LogEventLevel,
    Error: 'Error' as LogEventLevel,
    Fatal: 'Fatal' as LogEventLevel
};
