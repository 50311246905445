/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { StageOfLifeEnum } from './stageOfLifeEnum';


/**
 * DTO used when creating or updating a question
 */
export interface PostQuestionDTO { 
    /**
     * The identifier of the question
     */
    questionId?: string;
    /**
     * Sets type of question
     */
    discriminator: string;
    /**
     * The questionnaire this question belongs to, should not be changed after creation
     */
    questionnaireId?: string;
    /**
     * The stages of life this question is relevant for
     */
    stagesOfLife?: Array<StageOfLifeEnum>;
    /**
     * The order the questions appear in
     */
    order: number;
    /**
     * If true, this question is required
     */
    isRequired?: boolean;
    /**
     * The question text as it appears to the user
     */
    questionText: string;
    /**
     * An introduction to this question (and possibly following questions)
     */
    introduction?: string;
    /**
     * An explanatory text that appears beside a question
     */
    questionExplanation?: string;
    /**
     * Link to further explanation
     */
    explanationLink?: string;
    /**
     * The choice set of this question, only applies to choice questions
     */
    choiceSetId?: string;
    /**
     * The categories this question belongs to
     */
    categoryIds?: Array<string>;
}
