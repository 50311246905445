import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from './services/auth.service';
import { ModalComponent } from './views/shared/modal/modal.component';
import { CmsService } from './services/cms.service';
import { StateService } from './services/state.service';

import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  authUrl;

  resetPasswordUrl;
  safeAuthUrl: SafeResourceUrl;

  @ViewChild('loginFrame', { static: false }) loginFrame: ElementRef;
  @ViewChild('loginModal', { static: true }) loginModal: ModalComponent;

  @HostListener('window:message', ['$event'])
  messageRecieved(event: MessageEvent) {
    if (
      this.loginFrame && event.data === 'done'
      && event.source === (this.loginFrame.nativeElement as HTMLIFrameElement).contentWindow) {
      this.loginModal.close();
    }
  }

  constructor(
    public state: StateService,
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private cmsService: CmsService,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit() {
    this.cmsService.prefetch();
  }

  refreshAuthUrl() {
    this.authService.getAuthUrl().then(url => {
      this.authUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  
  sanitizeUrl(){
    this.safeAuthUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.resetPasswordUrl);
  }
}
