import { Injectable } from '@angular/core';
import { MyProfileComponent } from '../views/my-profile/my-profile.component';


@Injectable()
export class StateService {

  private _selectedPage: string;
  private _selectedTheme: string;
  private _redirectAfterLogin = false;
  private _isLoggedIn = false;
  private _isCoach: boolean | undefined = undefined;
  public myprofileComponent: MyProfileComponent; // reference to profile for saving questionnaire on logout (is set on comp.init)

  constructor() {
  }

  set selectedPage(p: string) {
    this._selectedPage = p;
  }
  get selectedPage(): string {
    return this._selectedPage;
  }
  set selectedTheme(t: string) {
    this._selectedTheme = t;
  }
  get selectedTheme(): string {
    return this._selectedTheme;
  }
  set isLoggedIn(b: boolean) { this._isLoggedIn = b; }
  get isLoggedIn(): boolean { return this._isLoggedIn; }
  set isCoach(b: boolean | undefined) { this._isCoach = b; }
  get isCoach(): boolean | undefined { return this._isCoach; }
  set redirectAfterLogin(b: boolean) {this._redirectAfterLogin = b; }
  get redirectAfterLogin(): boolean { return this._redirectAfterLogin; }

}
