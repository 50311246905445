import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { Observable, Subscriber } from 'rxjs';

/**
 * Wordt gebruikt voor tonen loginModal
 */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  @Output() opening = new EventEmitter<void>();

  private _isOpen = false;
  private subscribers: Array<Subscriber<void>> = [];

  constructor(private modalService: ModalService) { }

  ngOnInit() {
    if (!this.id) {
      throw new Error('attribute id is required on app-modal');
    }

    this.modalService.register(this);
  }

  open(): Observable<void> {
    this.opening.emit();
    this._isOpen = true;
    return new Observable((subscriber) => {
      this.subscribers.push(subscriber);
    });
  }

  close() {
    this._isOpen = false;
    this.subscribers.forEach(s => {
      s.next();
      s.complete();
    });
    this.subscribers = [];
  }

  isOpen(): boolean {
    return this._isOpen;
  }

  private bodyClick($event: MouseEvent) {
    if ($event.eventPhase === $event.AT_TARGET) {
      this.close();
    }
  }
}
