/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NextQuestionChoiceDTO } from './nextQuestionChoiceDTO';


/**
 * DTO used when creating or updating a next question
 */
export interface NextQuestionPostDTO { 
    /**
     * The current question
     */
    currentQuestionId?: string;
    /**
     * List of choices that lead to next questions. May be empty
     */
    nextQuestionChoices?: Array<NextQuestionChoiceDTO>;
}
