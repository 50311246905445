import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

/**
 * DO NOT USE IN COMPONENTS, ONLY INTENDED TO BE CONSUMED BY CmsService
 */
export interface WpMenuDTO {
  ID: string;
  name: string;
}

export interface WpMenutreeDTO {
  ID: string;
  menu_item_parent: string;
  title: string;
  post_excerpt: string;
  object: string;
  url: string;
}

export interface WpPostDTO {
  title: { rendered: string };
  content: { rendered: string };
}

export interface WpPageDTO {
  title: { rendered: string };
  content: { rendered: string };
  acf: { summary: string };
  slug: string;
}

export interface WpSearchDTO {
  title: string;
  summary: string;
  content: { rendered: string };
  slug: string;
}

@Injectable()
export class WordpressService {

  constructor(private httpClient: HttpClient) { }

  public GetMenus(): Observable<WpMenuDTO[]> {
    return this.httpClient.get<WpMenuDTO[]>(environment.wordpressUrl + '/wp-json/wp-api-menus/v2/menus/');
  }

  public GetMenu(id: string): Observable<WpMenuDTO> {
    return this.httpClient.get<WpMenuDTO>(environment.wordpressUrl + '/wp-json/wp-api-menus/v2/menus/' + id);
  }

  public GetMenuLocations() {
    return this.httpClient.get(environment.wordpressUrl + '/wp-json/wp-api-menus/v2/menu-locations/');
  }

  public GetMenuLocation(location: string) {
    return this.httpClient.get(environment.wordpressUrl + '/wp-json/wp-api-menus/v2/menu-locations/' + location);
  }

  public GetPost(id: string): Observable<WpPostDTO> {
    return this.httpClient.get<WpPostDTO[]>(environment.wordpressUrl + '/wp-json/wp/v2/posts?slug=' + id).pipe(map(posts => posts[0]));
  }

  public GetPage(id: string): Observable<WpPageDTO> {
    return this.httpClient.get<WpPageDTO[]>(environment.wordpressUrl + '/wp-json/wp/v2/pages?slug=' + id).pipe(map(posts => posts[0]));
  }

  public SearchPages(query: string): Observable<WpPageDTO[]> {
    return this.httpClient.get<WpPageDTO[]>(environment.wordpressUrl + '/wp-json/wp/v2/pages?search=' + query);
  }

  public GetPagesByDoelgroep(query: string): Observable<WpSearchDTO[]> {
    return this.httpClient.get<WpSearchDTO[]>(environment.wordpressUrl + '/wp-json/doelgroep/v1/search?for=' + query);
  }

  public GetPagesByLevensfase(query: string): Observable<WpSearchDTO[]> {
    return this.httpClient.get<WpSearchDTO[]>(environment.wordpressUrl + '/wp-json/levensfase/v1/get?id=' + query);
  }

  public GetHoofdMenuTree(): Observable<WpMenutreeDTO[]> {
    return this.httpClient.get<WpMenutreeDTO[]>(environment.wordpressUrl + '/wp-json/menutree/v1/get?id=6');
  }

  private contains(searchFor: string | RegExp, str: string): boolean {
    const pattern = new RegExp(searchFor, 'gi');
    return pattern.test(str);
  }

  private beginsWith(searchFor: string, str: string): boolean {
    const pattern = new RegExp('^' + searchFor, 'gi');
    return pattern.test(str);
  }

  private endsWith(searchFor: string, str: string): boolean {
    const pattern = new RegExp(searchFor + '$', 'gi');
    return pattern.test(str);
  }

  public LinkMagic(html: string) {
    if (html) {
      return html.replace(/(<a[^>]*href=")([^"]*)("[^>]*>)/, (match, start, url: string, end) => {
        let category = '/page';
        if (this.endsWith('.pdf', url)) {
          // if it's a link to a pdf
          category = '/wp';
        }
        return start + this.replaceWpLink(url, category) + end;
      });
    }
    return '';
  }

  public replaceWpLink(url: string, category = '/page') {
    if (url.includes(environment.wordpressUrl)) {
      return category + url.replace(environment.wordpressUrl, '').replace(/\/$/, '');
    }
    return url;
  }
}
