import { Injectable, EventEmitter } from '@angular/core';
import { WordpressService } from './wordpress.service';
import { WpMenu, WpMenuCache, WpMenuIds } from '../models/wp-menu';
import { WpHoofdMenu } from '../models/wp-hoofd-menu';
import { WpHoofdMenuItem } from '../models/wp-hoofd-menu-item';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { WpPage, WpPost } from '../models/wp-page';
import { DomSanitizer } from '@angular/platform-browser';
import { WpMenuItem } from '../models/wp-menu-item';
import { environment } from '../../environments/environment';

/**
 * Deze service haalt Wordpress data op via de wordpress.service en cached dit
 * Component in views/wordpress maken gebruik van deze service om de juiste content op te halen
 */
@Injectable()
export class CmsService {

  private hoofdMenuCache: WpHoofdMenu = null;
  private menuNamesCached = false;
  private menuCache: { [menu: string]: WpMenu } = {};
  private pageCache: Array<{ slug: string, page: WpPage }> = [];
  private postCache: Array<{ slug: string, post: WpPost }> = [];
  private latestSearchResult: Array<{ routerLink: string, page: WpPage }> = [];
  public searchResultDone: EventEmitter<Array<{ routerLink: string, page: WpPage }>> = new EventEmitter();

  constructor(private wordpressService: WordpressService, private domSanitizer: DomSanitizer) { }

  prefetch() {
    this.getHoofdMenuTree().subscribe();
    this.getLevensFaseNames().subscribe();
    this.getMenuLevensfase(WpMenuIds.onderwijs).subscribe();
    this.getMenuLevensfase(WpMenuIds.uitgeschakeld).subscribe();
    this.getMenuLevensfase(WpMenuIds.werkhebben).subscribe();
    this.getMenuLevensfase(WpMenuIds.werkzoeken).subscribe();
  }

  getHoofdMenuTree(): Observable<WpHoofdMenu> {
    if (this.hoofdMenuCache) {
      return of(this.hoofdMenuCache);
    }
    return this.wordpressService.GetHoofdMenuTree().pipe(map(obj => {
      // create an object tree
      this.hoofdMenuCache = { items: [] };
      const subpages: Array<WpHoofdMenuItem> = new Array<WpHoofdMenuItem>();
      for (const item of obj) {
        const routerLink = item.object === 'custom' ?
          this.makeRelative(item.url) :
          this.wordpressService.replaceWpLink(item.url);
        const routeParts = routerLink.split('/');
        const slug = routeParts[routeParts.length - 1];
        const page = {
          id: item.ID,
          parent_id: item.menu_item_parent,
          title: item.title,
          link: routerLink,
          slug: slug,
          exerpt: item.post_excerpt,
          children: []
        } as WpHoofdMenuItem;
        if (page.parent_id === '0') {
          this.hoofdMenuCache.items.push(page);
        } else {
          page.link = `/page/${page.link.split('/')[3]}`;
          subpages.push(page);
        }
      }
      for (const subitem of subpages) {
        const parent = this.hoofdMenuCache.items.find(p => p.id === subitem.parent_id);
        if (parent) {
          parent.children.push(subitem);
        }
      }
      return this.hoofdMenuCache;
    }));
  }

  getLevensFaseNames(): Observable<WpMenuCache> {
    if (this.menuNamesCached) {
      return of(this.menuCache);
    }
    return this.wordpressService.GetMenus().pipe(map(obj => {
      for (const item of obj) {
        const id = item.ID;
        if (this.menuCache[id]) {
          const cachedMenu = this.menuCache[id];
          cachedMenu.name = item.name;
        } else {
          this.menuCache[id] = {
            name: item.name,
            id: id,
            items: []
          };
        }
      }
      this.menuNamesCached = true;
      return this.menuCache;
    }));
  }

  getMenuLevensfase(menuId: string): Observable<WpMenu> {
    let cachedMenu = this.menuCache[menuId];
    if (cachedMenu) {
      menuId = cachedMenu.id;
      if (cachedMenu.items.length > 0) {
        return of(cachedMenu);
      }
    } else {
      this.menuCache[menuId] = {
        id: menuId,
        name: 'to-be-set',
        items: []
      };
      cachedMenu = this.menuCache[menuId];
    }

    return this.wordpressService.GetPagesByLevensfase(menuId).pipe(map(obj => {
      for (const item of obj) {
        const menuItem: WpMenuItem = {
          title: item.title,
          slug: item.slug,
          summary: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(item.summary))
        };

        cachedMenu.items.push(menuItem);
      }
      return cachedMenu;
    }));
  }

  getPost(slug: string): Observable<WpPost> {
    const cachedPost = this.getCachedPost(slug);
    if (cachedPost) {
      // move page back to top of cache
      this.addToPostCache(slug, cachedPost);
      return of(cachedPost);
    }

    return this.wordpressService.GetPost(slug).pipe(map(obj => {
      if (obj) {
        const post: WpPost = {
          title: obj.title.rendered,
          htmlString: obj.content.rendered,
          htmlContent: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.content.rendered))
        };
        this.addToPostCache(slug, post);
        return post;
      }
      const noPost: WpPost = {
        title: 'Pagina niet gevonden',
        htmlString: '<div>De opgevraagde pagina is niet gevonden</div>',
        htmlContent: '<div>De opgevraagde pagina is niet gevonden</div>'
      };
      return noPost;
    }));
  }

  getPostWithoutPTags(slug: string): Observable<WpPost> {
    const cachedPost = this.getCachedPost(slug);
    if (cachedPost) {
      // move page back to top of cache
      this.addToPostCache(slug, cachedPost);
      return of(cachedPost);
    }

    return this.wordpressService.GetPost(slug).pipe(map(obj => {
      if (obj) {
        const htmlWithoutPTag = obj.content.rendered.replace(/<\/?p[^>]*>/g, '');
        const post: WpPost = {
          title: obj.title.rendered,
          htmlString: obj.content.rendered,
          htmlContent: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(htmlWithoutPTag))
        };
        this.addToPostCache(slug, post);
        return post;
      }
      const noPost: WpPost = {
        title: 'Pagina niet gevonden',
        htmlString: '<div>De opgevraagde pagina is niet gevonden</div>',
        htmlContent: '<div>De opgevraagde pagina is niet gevonden</div>'
      };
      return noPost;
    }));
  }
  getPostWithoutImages(slug: string): Observable<WpPost> {
    const cachedPost = this.getCachedPost(slug);
    if (cachedPost) {
      // move page back to top of cache
      this.addToPostCache(slug, cachedPost);
      return of(cachedPost);
    }

    return this.wordpressService.GetPost(slug).pipe(map(obj => {
      if (obj) {
        const htmlWithoutImg = obj.content.rendered.replace(/<img .*?>/g, '');
        const post: WpPost = {
          title: obj.title.rendered,
          htmlString: obj.content.rendered,
          htmlContent: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(htmlWithoutImg))
        };
        this.addToPostCache(slug, post);
        return post;
      }
      const noPost: WpPost = {
        title: 'Pagina niet gevonden',
        htmlString: '<div>De opgevraagde pagina is niet gevonden</div>',
        htmlContent: '<div>De opgevraagde pagina is niet gevonden</div>'
      };
      return noPost;
    }));
  }

  getPage(slug: string): Observable<WpPage> {
    const cachedPage = this.getCachedPage(slug);
    if (cachedPage) {
      // move page back to top of cache
      this.addToPageCache(slug, cachedPage);
      return of(cachedPage);
    }

    return this.wordpressService.GetPage(slug).pipe(map(obj => {
      if (obj) {
        const page: WpPage = {
          title: obj.title.rendered,
          htmlContent: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.content.rendered)),
          excerpt: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.acf.summary)),
        };
        this.addToPageCache(slug, page);
        return page;
      }
      const noPage: WpPage = {
        title: 'Pagina niet gevonden',
        htmlContent: '<div>De opgevraagde pagina is niet gevonden</div>',
        excerpt: 'De opgevraagde pagina is niet gevonden'
      };
      return noPage;
    }));
  }

  private makeRelative(url: string) {
    if (url.startsWith(environment.myUrl)) {
      return url.replace(environment.myUrl, '');
    }
    return url;
  }

  private addToPageCache(slug: string, page: WpPage) {
    const oldIdx = this.pageCache.findIndex(i => i.slug === slug);
    if (oldIdx !== -1) {
      this.pageCache.splice(oldIdx, 1);
    }

    this.pageCache.push({
      slug: slug,
      page: page
    });

    if (this.pageCache.length > 10) {
      this.pageCache.shift();
    }
  }

  private getCachedPage(slug: string): WpPage {
    for (const idx in this.pageCache) {
      if (this.pageCache[idx].slug === slug) {
        return this.pageCache[idx].page;
      }
    }
    return null;
  }

  private addToPostCache(slug: string, post: WpPost) {
    const oldIdx = this.postCache.findIndex(i => i.slug === slug);
    if (oldIdx !== -1) {
      this.postCache.splice(oldIdx, 1);
    }

    this.postCache.push({
      slug: slug,
      post: post
    });

    if (this.postCache.length > 10) {
      this.postCache.shift();
    }
  }
  private getCachedPost(slug: string): WpPost {
    for (const idx in this.postCache) {
      if (this.postCache[idx].slug === slug) {
        return this.postCache[idx].post;
      }
    }
    return null;
  }

  // search
  search(query: string): Observable<{ routerLink: string, page: WpPage }[]> {

    this.latestSearchResult = [];

    return this.wordpressService.SearchPages(query).pipe(map(res => {
      if (res) {
        // add res to pageCache
        res.forEach(obj => {
          const page: WpPage = {
            title: obj.title.rendered,
            htmlContent: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.content.rendered)),
            excerpt: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.acf.summary))
          };
          this.addToPageCache(obj.slug, page);
          this.latestSearchResult.push({ routerLink: `/page/${obj.slug}`, page });
        });
        this.searchResultDone.emit(this.latestSearchResult);
        return this.latestSearchResult;
      }
      this.searchResultDone.emit([]);
      return [];
    }));
  }

  // search by tag
  searchByDoelgroep(query: string): Observable<{ routerLink: string, page: WpPage }[]> {
    this.latestSearchResult = [];
    return this.wordpressService.GetPagesByDoelgroep(query).pipe(map(res => {
      if (res) {
        res.forEach(obj => {
          const page: WpPage = {
            title: obj.title,
            htmlContent: '',
            excerpt: this.domSanitizer.bypassSecurityTrustHtml(this.wordpressService.LinkMagic(obj.summary))
          };
          this.latestSearchResult.push({ routerLink: `/page/${obj.slug}`, page });
        });
        this.searchResultDone.emit(this.latestSearchResult);
        return this.latestSearchResult;
      }
      this.searchResultDone.emit([]);
      return [];
    }));
  }
}
