/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a category
 */
export interface CategoryDTO { 
    /**
     * The identifier of the category
     */
    categoryId?: string;
    /**
     * The report this category belongs to
     */
    reportId?: string;
    /**
     * Category name
     */
    name: string;
    /**
     * The order this category appears in
     */
    order: number;
}
