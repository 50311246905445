/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogEventLevel } from './logEventLevel';


/**
 * Event that is logged to the database
 */
export interface LogEventDTO { 
    /**
     * Source of the event
     */
    source?: string;
    /**
     * Timestamp of the event
     */
    timestamp?: Date;
    level?: LogEventLevel;
    /**
     * Description of the event
     */
    message?: string;
    /**
     * Details of the exception
     */
    exception?: string;
    /**
     * Details of the exception
     */
    exceptionDetail?: string;
    /**
     * Name of the class from which the log originated
     */
    sourceContext?: string;
    /**
     * Action id
     */
    actionId?: string;
    /**
     * Action name
     */
    actionName?: string;
    /**
     * Request id
     */
    requestId?: string;
    /**
     * HTTP request path
     */
    requestPath?: string;
}
