/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a stage of life
 */
export type StageOfLifeEnum = 'Education' | 'JobSearch' | 'HasJob' | 'Disabled';

export const StageOfLifeEnum = {
    Education: 'Education' as StageOfLifeEnum,
    JobSearch: 'JobSearch' as StageOfLifeEnum,
    HasJob: 'HasJob' as StageOfLifeEnum,
    Disabled: 'Disabled' as StageOfLifeEnum
};
