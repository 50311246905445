import { Injectable } from '@angular/core';
import { UserManager, User, WebStorageStateStore, UserManagerSettings } from 'oidc-client';
import { environment } from '../../environments/environment';
import { Observable, Subscriber, of } from 'rxjs';
import { ModalService } from './modal.service';

function getOIDCSettings(): UserManagerSettings {
  return {
    authority: environment.idPUrl,
    client_id: 'wwa-web',
    response_type: 'id_token token',
    scope: 'openid profile coreapi roles',
    // Redirect uri's (safari drops fragment after url if there is no trailing slash http://stackoverflow.com/a/18698307/1108891)
    redirect_uri: environment.myUrl + '/auth/auth.html',
    silent_redirect_uri: environment.myUrl + '/auth/silent-auth.html',
    post_logout_redirect_uri: environment.myUrl + '/',
    // Number of seconds before access token expires to raise event
    accessTokenExpiringNotificationTime: 360,
    // Remove oidc claims from profile
    filterProtocolClaims: true,
    // Try to load extra data into profile from userinfo endpoint
    loadUserInfo: true,
    // Persist state store
    stateStore: new WebStorageStateStore({ prefix: 'oidc.', store: window.localStorage }),
    // Clock skew in seconds
    clockSkew: 300
  };
}

@Injectable()
export class AuthService {

  private _settings: UserManagerSettings;
  private _mgr: UserManager;
  private _user: User;
  private _loginStatusSubscribers: Array<Subscriber<User>> = [];
  private _role: string;

  constructor(private modalService: ModalService) {
    this._settings = getOIDCSettings();
    this._mgr = new UserManager(this._settings);

    this._mgr.events.addUserLoaded(user => {
      this.loginStatusChange(user);
    });

    this._mgr.events.addUserUnloaded(() => {
      this.loginStatusChange(null);
    });

    this._mgr.getUser().then(user => {
      this.loginStatusChange(user);
    });
  }

  requestLogin(): Observable<void> {
    const modalObservable = this.modalService.open('login');
    if (modalObservable) {
      modalObservable.subscribe(() => {
        this._mgr.getUser().then(user => {
          this.loginStatusChange(user);
        });
      });
      return modalObservable;
    } else {
      return of();
    }
  }

  requestLogout() {
    localStorage.removeItem('redirectUrl'); // remove redirectUrl if set
    this._mgr.signoutRedirect();
  }

  getAuthUrl(): Promise<string> {
    return this._mgr.createSigninRequest().then(req => {
      return req.url;
    });
  }

  loginStatus(): Observable<User> {
    return new Observable(subscriber => {
      if (this.isLoggedIn(this._user)) {
        subscriber.next(this._user);
      } else {
        subscriber.next(null);
      }
      this._loginStatusSubscribers.push(subscriber);
    });
  }

  private loginStatusChange(newUser: User) {
    this._user = newUser;
    this._loginStatusSubscribers.forEach(s => {
      if (this.isLoggedIn(this._user)) {
        s.next(this._user);
      } else {
        s.next(null);
      }
    });
  }

  private isLoggedIn(user: User) {
    return !!user && !user.expired;
  }

  getAccessToken(): string {
    return this._user.access_token;
  }

  getProfile() {
    return this._user.profile;
  }

  getUser() {
    return this._user;
  }

  public isUserLoggedIn(): boolean {
    return this.isLoggedIn(this._user);
  }
  hasUserRole(role: string) {
    if (Array.isArray(this._user.profile.roles)) {
      return this._user.profile.roles.some(x => x === role);
    } else {
      return this._user.profile.roles === role;
    }
  }
}
