/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ChoiceSetDTO } from './choiceSetDTO';
import { SelectedChoiceDTO } from './selectedChoiceDTO';


/**
 * Represents the questions relevant to a given user, with the supplied answers
 */
export interface QuestionAndAnswersDTO { 
    /**
     * The identifier of the question
     */
    questionId?: string;
    /**
     * Sets type of question
     */
    discriminator: string;
    /**
     * The questionnaire this question belongs to, should not be changed after creation
     */
    questionnaireId?: string;
    /**
     * The order the questions appear in
     */
    order: number;
    /**
     * If true, this question is required
     */
    isRequired?: boolean;
    /**
     * The question text as it appears to the user
     */
    questionText: string;
    /**
     * An introduction to this question (and possibly following questions)
     */
    introduction?: string;
    /**
     * An explanatory text that appears beside a question
     */
    questionExplanation?: string;
    /**
     * Link to further explanation
     */
    explanationLink?: string;
    choiceSet?: ChoiceSetDTO;
    /**
     * List of selected choices
     */
    selectedChoices?: Array<SelectedChoiceDTO>;
    /**
     * The answer to an open question
     */
    answerText?: string;
}
