import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StateService } from '../../../services/state.service';
import { WpMenuEnum, WpMenuIds } from '../../../models/wp-menu';
import { CmsService } from '../../../services/cms.service';

@Component({
  selector: 'app-stages-of-life-menu',
  templateUrl: './stages-of-life-menu.component.html',
  styleUrls: ['./stages-of-life-menu.component.scss']
})
export class StagesOfLifeMenuComponent implements OnInit {

  @ViewChild('subHeader', { static: true }) subHeader: ElementRef;
  WpMenuEnum = WpMenuEnum;
  menus = { onderwijs: { name: '' }, werkzoeken: { name: '' }, werkhebben: { name: '' }, uitgeschakeld: { name: '' } };

  constructor(
    public state: StateService,
    private cmsService: CmsService,
  ) {
    this.cmsService.getLevensFaseNames().subscribe(menus => {
      this.menus.onderwijs = menus[WpMenuIds.onderwijs];
      this.menus.werkhebben = menus[WpMenuIds.werkhebben];
      this.menus.werkzoeken = menus[WpMenuIds.werkzoeken];
      this.menus.uitgeschakeld = menus[WpMenuIds.uitgeschakeld];
    });
  }

  ngOnInit() {
  }
}
