import { WpMenuItem } from './wp-menu-item';

export interface WpMenu {
  id: string;
  name: string;
  items: Array<WpMenuItem>;
}

export interface WpMenuCache {
  [menuId: string]: WpMenu;
}

export class WpMenuRouteNames {
  // static menu_6 = 'hoofdmenu';  not a route
  static menu_5 = 'onderwijs';
  static menu_4 = 'werkhebben';
  static menu_2 = 'werkzoeken';
  static menu_3 = 'uitgeschakeld';
}

export class WpMenuIds {
  static hoofdmenu = '6';
  static onderwijs = '5';
  static werkhebben = '4';
  static werkzoeken = '2';
  static uitgeschakeld = '3';
}
export enum WpMenuEnum {
  onderwijs, werkhebben, werkzoeken, uitgeschakeld, hoofdmenu
}
