/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogEventLevel } from './logEventLevel';


/**
 * Input for http request requesting a list of log events
 */
export interface ListEventsInputDTO { 
    /**
     * Index of the selected page
     */
    pageIndex?: number;
    /**
     * Number of events per page
     */
    pageSize?: number;
    /**
     * Timestamp from
     */
    timestampFrom?: Date;
    /**
     * Timestamp to
     */
    timestampTo?: Date;
    /**
     * Selected value of the source filter
     */
    source?: string;
    /**
     * Field that is used for sorting
     */
    sort?: string;
    /**
     * Direction for sorting
     */
    sortDirection?: string;
    minLevel?: LogEventLevel;
    /**
     * Field that is used for filtering
     */
    filterKey?: string;
    /**
     * Value of filter
     */
    filterValue?: string;
}
