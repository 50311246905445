/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a choice
 */
export interface ChoiceDTO { 
    /**
     * Identifier of the choice
     */
    choiceId?: string;
    /**
     * The order this choice appears in
     */
    order?: number;
    /**
     * Textual representation of the choice
     */
    choiceText?: string;
    /**
     * The set this choice belongs to
     */
    choiceSetId?: string;
    /**
     * Identifier of next question
     */
    nextQuestionQuestionId?: string;
    /**
     * Is this the last question of this questionnaire?
     */
    isLastQuestion?: boolean;
}
