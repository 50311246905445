import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule, Configuration } from './generated';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { StateService } from './services/state.service';

import { SharedModule } from './views/shared/shared.module';
import { ModalService } from './services/modal.service';
import { CmsService } from './services/cms.service';
import { WordpressService } from './services/wordpress.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule,
    SharedModule,
    BrowserAnimationsModule
  ],
  providers: [
    CmsService,
    WordpressService,
    AuthGuardService,
    AuthService,
    ModalService,
    StateService, {
      provide: Configuration,
      useFactory: (authService: AuthService) => {
        return new Configuration({
          basePath: environment.coreApiUrl,
          accessToken: () => authService.getAccessToken()
        });
      },
      deps: [AuthService],
      multi: false
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
