/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a user
 */
export interface UserDTO { 
    /**
     * Id of the user
     */
    id?: string;
    /**
     * User name of the user
     */
    username?: string;
}
