/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a link to a next question that depends on the choice that is made in the current question
 */
export interface NextQuestionDTO { 
    /**
     * The current question
     */
    currentQuestionId?: string;
    /**
     * The choice that leads to the next question
     */
    choiceId?: string;
    /**
     * The next question when Choice is made  null when there should be no more questions
     */
    nextQuestionQuestionId?: string;
    /**
     * Is this the last question of this questionnaire?
     */
    isLastQuestion?: boolean;
}
