import { Injectable } from '@angular/core';
import { CanLoad, CanActivate, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  notAuthorizedUrl = 'auth/notauthorized';

  canLoad(route: Route): boolean {
    // if (!environment.useBackEnd) { return true; }
    const url: string = route.path;
    if (url == 'admin') {
      return this.checkIfUserHasAccess('admin');
    } else {
      return this.checkIfUserHasAccess();
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (!environment.useBackEnd) { return true; }
    const url: string = state.url;
    if (url.split('/')[0] == 'admin') {
      return this.checkIfUserHasAccess('admin');
    } else {
      return this.checkIfUserHasAccess();
    }
  }

  checkIfUserHasAccess(needsRole = '') {
    // if (!environment.useBackEnd) { return true; }
    if (!this.authService.isUserLoggedIn()) {
      this.setRedirectUrl();
      this.router.navigate([this.notAuthorizedUrl, 'notloggedin']);
      return false;
    } else {
      if (needsRole !== '' && !this.authService.hasUserRole(needsRole)) {
        this.setRedirectUrl();
        this.router.navigate([this.notAuthorizedUrl, 'notcorrectrole']);
        return false;
      } else {
        return true;
      }
    }
  }

  // set the redirectUrl (current page), this is used in authorized.component to redirect after login
  setRedirectUrl() {
    localStorage.setItem('redirectUrl', location.pathname);
  }
}
