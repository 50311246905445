/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents a questionnaire
 */
export interface QuestionnaireDTO { 
    /**
     * The identifier of the questionnaire
     */
    questionnaireId?: string;
    /**
     * The id of the group this questionnaire belongs to
     */
    groupId?: string;
    /**
     * The order the questionnaires appear in
     */
    order: number;
    /**
     * Name of the questionnaire as shown to the user
     */
    displayName: string;
    /**
     * An introduction to the questionnaire
     */
    introduction?: string;
}
