/**
 * WerkWebAutisme Core API
 * WerkWebAutisme Core API
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Represents the progress made by a user in filling in the questionnaire
 */
export interface ProgressDTO { 
    /**
     * Number of answered questionnaires
     */
    answered?: number;
    /**
     * Number of total questionnaires
     */
    total?: number;
}
